<template>
  <ModuleView disable-breadcrumb>
    <template slot="header">
      <TabNavigator :tabs="commonTagsToggleList" @tabWasChanged="resetFilters">
        <div slot="buttons">
          <ButtonCreate
            v-if="isCreateButtonVisible"
            route-name="editorialTag_new"
          />
        </div>
      </TabNavigator>
    </template>
    <!-- Geneea Tags -->
    <template v-if="currentTab === 'geneeaTags'" slot="body">
      <GeneeaTagsList />
    </template>
    <!-- Editorial Tags -->
    <template v-if="currentTab === 'editorialTags'" slot="body">
      <EditorialTagsList/>
    </template>
  </ModuleView>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ModuleView from '@/components/ModuleView'
import PermissionService from '@/services/PermissionService'
import ButtonCreate from '@/components/shared/ButtonCreate.vue'
import TabNavigator from '@/components/TabNavigator.vue'
import GeneeaTagsList from '@/views/tag/geneeaTags/GeneeaTagsListView.vue'
import EditorialTagsList from '@/views/tag/editorialTags/EditorialTagsListView.vue'

export default {
  name: 'CommonTagsList',
  components: {
    ButtonCreate,
    ModuleView,
    TabNavigator,
    GeneeaTagsList,
    EditorialTagsList
  },
  data () {
    return {
      enableSaveBtn: false,
      dataLoaded: false,
      record: {
        geneeaTag: null,
        editorialTag: null
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('tabNavigator', ['currentTab']),
    commonTagsToggleList () {
      return [
        {
          id: 'geneeaTags',
          title: this.$t('tag.commonTags.geneeaTags')
        },
        {
          id: 'editorialTags',
          title: this.$t('tag.commonTags.editorialTags')
        }
      ]
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton) && this.currentTab === 'editorialTags'
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    resetFilters () {
      this.$store.commit('tag/setPage', 1)
      this.$store.commit('tag/setFilter', { id: '', title: '' })
    }
  }
}
</script>

<style lang="scss" scoped>

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  margin-bottom: 0.5rem ;

  button {
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}

button.btn-disabled {
  background-color: #8b8b8b;
  &:hover {
    background-color: #8b8b8b;
  }
}

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
